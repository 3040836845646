import revive_payload_client_j7qd0PIM3S from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VX7JoHR1AE from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IaRno8XL9X from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_fuU3PlXzmv from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dKELWjelaT from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_WOc5Ymwa0T from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_NNG5N0FhH2 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.2.4_eslint@9.21.0_jiti@2.4._696c430eade4c64e6b492876e3cf88ce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_1MRn6ioxYf from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.7_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_dJTwIpYCZc from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.7_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_V1vRw77VfD from "/app/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_OHU0vggoM9 from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_se2YLuVaVZ from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@type_f7e1680f8da383d18d29a09b6655e76b/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_sZGbScZEgF from "/app/apps/onboarding/plugins/page-view-event.client.ts";
export default [
  revive_payload_client_j7qd0PIM3S,
  unhead_VX7JoHR1AE,
  router_IaRno8XL9X,
  payload_client_fuU3PlXzmv,
  navigation_repaint_client_dKELWjelaT,
  chunk_reload_client_WOc5Ymwa0T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NNG5N0FhH2,
  switch_locale_path_ssr_1MRn6ioxYf,
  i18n_dJTwIpYCZc,
  plugin_client_V1vRw77VfD,
  plugin_OHU0vggoM9,
  plugin_se2YLuVaVZ,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  image_thumbnail_update_G2rT6oNNpa,
  page_view_event_client_sZGbScZEgF
]