
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93rZRxTCltEUMeta } from "/app/apps/onboarding/pages/[orgId]/[context]/[classId]/invitation/[id].vue?macro=true";
import { default as _91type_93_45passwordeApc6ZGLtGMeta } from "/app/apps/onboarding/pages/[type]-password.vue?macro=true";
import { default as accessibilityjZLATnH1UiMeta } from "/app/apps/onboarding/pages/accessibility.vue?macro=true";
import { default as authenticateA5HXlWZUzFMeta } from "/app/apps/onboarding/pages/authenticate.vue?macro=true";
import { default as child_45registrationSDK5Y1naHyMeta } from "/app/apps/onboarding/pages/child-registration.vue?macro=true";
import { default as desktop_45loginbEuQLSV2T7Meta } from "/app/apps/onboarding/pages/desktop-login.vue?macro=true";
import { default as edulogTXKuw3ysjJMeta } from "/app/apps/onboarding/pages/edulog.vue?macro=true";
import { default as error_45card6j6KtmzetRMeta } from "/app/apps/onboarding/pages/error-card.vue?macro=true";
import { default as generic_45lti_45errortkxnL5U5GPMeta } from "/app/apps/onboarding/pages/generic-lti-error.vue?macro=true";
import { default as homeFDS30BusBuMeta } from "/app/apps/onboarding/pages/home.vue?macro=true";
import { default as institution_45requestDFeFXi0c4mMeta } from "/app/apps/onboarding/pages/institution-request.vue?macro=true";
import { default as learner_45age_45_91type_93GqBVIYt5vzMeta } from "/app/apps/onboarding/pages/learner-age-[type].vue?macro=true";
import { default as login_91_91segment_93_93hgTtOFMUBbMeta } from "/app/apps/onboarding/pages/login[[segment]].vue?macro=true";
import { default as _91ltik_93Acr4DMxPqEMeta } from "/app/apps/onboarding/pages/lti-activate-course-material/[spaceId]/class/[classId]/product/[productCode]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_93bmB3OZ9hFKMeta } from "/app/apps/onboarding/pages/lti-component-selection/[spaceId]/class/[classId]/bundle/[bundleCode]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as lti_45errorSUSaVXO2HbMeta } from "/app/apps/onboarding/pages/lti-error.vue?macro=true";
import { default as _91errorType_930JfnEjLW5VMeta } from "/app/apps/onboarding/pages/lti-onboarding-error/[errorType].vue?macro=true";
import { default as lti_45onboardingUyXkOAOAaoMeta } from "/app/apps/onboarding/pages/lti-onboarding.vue?macro=true";
import { default as _91ltik_93Y3DPYiUg3zMeta } from "/app/apps/onboarding/pages/lti-product-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_93kDfkhSYzQ1Meta } from "/app/apps/onboarding/pages/lti-progress-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue?macro=true";
import { default as _91ltik_938C6FNZZzkkMeta } from "/app/apps/onboarding/pages/lti-teacher-dashboard/[spaceId]/class/[classId]/ltik/[ltik].vue?macro=true";
import { default as oktaWyeQaTzaaZMeta } from "/app/apps/onboarding/pages/okta.vue?macro=true";
import { default as parental_45consentO3wTq1mVVfMeta } from "/app/apps/onboarding/pages/parental-consent.vue?macro=true";
import { default as pesSwZumVtrx5Meta } from "/app/apps/onboarding/pages/pes.vue?macro=true";
import { default as privacyFEA7SJJYubMeta } from "/app/apps/onboarding/pages/privacy.vue?macro=true";
import { default as register_45_91user_93ZbxKT0U3tAMeta } from "/app/apps/onboarding/pages/register-[user].vue?macro=true";
import { default as regoptions8USvTGKJj9Meta } from "/app/apps/onboarding/pages/regoptions.vue?macro=true";
import { default as social_45loginrzjZZFAV66Meta } from "/app/apps/onboarding/pages/social-login.vue?macro=true";
import { default as terms56rJL2k8eSMeta } from "/app/apps/onboarding/pages/terms.vue?macro=true";
import { default as verification_45link_45expiredZuz0h011W4Meta } from "/app/apps/onboarding/pages/verification-link-expired.vue?macro=true";
import { default as homeRnoOz6vqi0Meta } from "~/pages/home.vue?macro=true";
export default [
  {
    name: "orgId-context-classId-invitation-id",
    path: "/:orgId()/:context()/:classId()/invitation/:id()",
    meta: _91id_93rZRxTCltEUMeta || {},
    component: () => import("/app/apps/onboarding/pages/[orgId]/[context]/[classId]/invitation/[id].vue")
  },
  {
    name: "type-password",
    path: "/:type()-password",
    meta: _91type_93_45passwordeApc6ZGLtGMeta || {},
    component: () => import("/app/apps/onboarding/pages/[type]-password.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    meta: accessibilityjZLATnH1UiMeta || {},
    component: () => import("/app/apps/onboarding/pages/accessibility.vue")
  },
  {
    name: "authenticate",
    path: "/authenticate",
    meta: authenticateA5HXlWZUzFMeta || {},
    component: () => import("/app/apps/onboarding/pages/authenticate.vue")
  },
  {
    name: "child-registration",
    path: "/child-registration",
    meta: child_45registrationSDK5Y1naHyMeta || {},
    component: () => import("/app/apps/onboarding/pages/child-registration.vue")
  },
  {
    name: "desktop-login",
    path: "/desktop-login",
    component: () => import("/app/apps/onboarding/pages/desktop-login.vue")
  },
  {
    name: "edulog",
    path: "/edulog",
    meta: edulogTXKuw3ysjJMeta || {},
    component: () => import("/app/apps/onboarding/pages/edulog.vue")
  },
  {
    name: "error-card",
    path: "/error-card",
    component: () => import("/app/apps/onboarding/pages/error-card.vue")
  },
  {
    name: "generic-lti-error",
    path: "/generic-lti-error",
    meta: generic_45lti_45errortkxnL5U5GPMeta || {},
    component: () => import("/app/apps/onboarding/pages/generic-lti-error.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeFDS30BusBuMeta || {},
    component: () => import("/app/apps/onboarding/pages/home.vue")
  },
  {
    name: "institution-request",
    path: "/institution-request",
    meta: institution_45requestDFeFXi0c4mMeta || {},
    component: () => import("/app/apps/onboarding/pages/institution-request.vue")
  },
  {
    name: "learner-age-type",
    path: "/learner-age-:type()",
    meta: learner_45age_45_91type_93GqBVIYt5vzMeta || {},
    component: () => import("/app/apps/onboarding/pages/learner-age-[type].vue")
  },
  {
    name: "loginsegment",
    path: "/login:segment?",
    meta: login_91_91segment_93_93hgTtOFMUBbMeta || {},
    component: () => import("/app/apps/onboarding/pages/login[[segment]].vue")
  },
  {
    name: "lti-activate-course-material-spaceId-class-classId-product-productCode-resource-resource-ltik-ltik",
    path: "/lti-activate-course-material/:spaceId()/class/:classId()/product/:productCode()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93Acr4DMxPqEMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-activate-course-material/[spaceId]/class/[classId]/product/[productCode]/resource/[resource]/ltik/[ltik].vue")
  },
  {
    name: "lti-component-selection-spaceId-class-classId-bundle-bundleCode-resource-resource-ltik-ltik",
    path: "/lti-component-selection/:spaceId()/class/:classId()/bundle/:bundleCode()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93bmB3OZ9hFKMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-component-selection/[spaceId]/class/[classId]/bundle/[bundleCode]/resource/[resource]/ltik/[ltik].vue")
  },
  {
    name: "lti-error",
    path: "/lti-error",
    meta: lti_45errorSUSaVXO2HbMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-error.vue")
  },
  {
    name: "lti-onboarding-error-errorType",
    path: "/lti-onboarding-error/:errorType()",
    meta: _91errorType_930JfnEjLW5VMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-onboarding-error/[errorType].vue")
  },
  {
    name: "lti-onboarding",
    path: "/lti-onboarding",
    meta: lti_45onboardingUyXkOAOAaoMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-onboarding.vue")
  },
  {
    name: "lti-product-selection-spaceId-class-classId-resource-resource-ltik-ltik",
    path: "/lti-product-selection/:spaceId()/class/:classId()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93Y3DPYiUg3zMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-product-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue")
  },
  {
    name: "lti-progress-selection-spaceId-class-classId-resource-resource-ltik-ltik",
    path: "/lti-progress-selection/:spaceId()/class/:classId()/resource/:resource()/ltik/:ltik()",
    meta: _91ltik_93kDfkhSYzQ1Meta || {},
    component: () => import("/app/apps/onboarding/pages/lti-progress-selection/[spaceId]/class/[classId]/resource/[resource]/ltik/[ltik].vue")
  },
  {
    name: "lti-teacher-dashboard-spaceId-class-classId-ltik-ltik",
    path: "/lti-teacher-dashboard/:spaceId()/class/:classId()/ltik/:ltik()",
    meta: _91ltik_938C6FNZZzkkMeta || {},
    component: () => import("/app/apps/onboarding/pages/lti-teacher-dashboard/[spaceId]/class/[classId]/ltik/[ltik].vue")
  },
  {
    name: "okta",
    path: "/okta",
    meta: oktaWyeQaTzaaZMeta || {},
    component: () => import("/app/apps/onboarding/pages/okta.vue")
  },
  {
    name: "parental-consent",
    path: "/parental-consent",
    meta: parental_45consentO3wTq1mVVfMeta || {},
    component: () => import("/app/apps/onboarding/pages/parental-consent.vue")
  },
  {
    name: "pes",
    path: "/pes",
    meta: pesSwZumVtrx5Meta || {},
    component: () => import("/app/apps/onboarding/pages/pes.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyFEA7SJJYubMeta || {},
    component: () => import("/app/apps/onboarding/pages/privacy.vue")
  },
  {
    name: "register-user",
    path: "/register-:user()",
    meta: register_45_91user_93ZbxKT0U3tAMeta || {},
    component: () => import("/app/apps/onboarding/pages/register-[user].vue")
  },
  {
    name: "regoptions",
    path: "/regoptions",
    meta: regoptions8USvTGKJj9Meta || {},
    component: () => import("/app/apps/onboarding/pages/regoptions.vue")
  },
  {
    name: "social-login",
    path: "/social-login",
    meta: social_45loginrzjZZFAV66Meta || {},
    component: () => import("/app/apps/onboarding/pages/social-login.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms56rJL2k8eSMeta || {},
    component: () => import("/app/apps/onboarding/pages/terms.vue")
  },
  {
    name: "verification-link-expired",
    path: "/verification-link-expired",
    meta: verification_45link_45expiredZuz0h011W4Meta || {},
    component: () => import("/app/apps/onboarding/pages/verification-link-expired.vue")
  },
  {
    name: "index",
    path: "/",
    meta: homeRnoOz6vqi0Meta || {},
    component: () => import("~/pages/home.vue")
  }
]